<template>
    <el-dialog
        title="Rule Editor"
        :visible.sync="dialogVisible"
        append-to-body
        width="60%"
        class="rule-go-json-editor"
        :before-close="handleClose">
        <el-form v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)">
            <codemirror v-model="model.contentText" :options="codeMirrorOptions" />
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">Cancel</el-button>
            <el-button class="cereb-button" @click="handleConfirm">Confirm</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { Dialog, Form, } from 'element-ui';
import * as api from '@/api';
// require component
import { codemirror } from 'vue-codemirror'

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css'; // 选择一个主题
import 'codemirror/mode/javascript/javascript.js'; // 导入 JSON 模式

export default {
    components: {
        ElDialog: Dialog,
        ElForm: Form,
        codemirror
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dialogVisible: this.value,
            loading: false,
            model: {
                contentText: ''
            },
            codeMirrorOptions: {
                mode: 'application/json',
                theme: 'material', // 选择一个主题
                lineNumbers: true, // 显示行号
                tabSize: 2, // 设置 Tab 大小
                indentWithTabs: true, // 使用空格进行缩进
                autoCloseBrackets: true, // 自动闭合括号
                matchBrackets: true, // 匹配括号
                gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"], // 显示行号和折叠
                foldGutter: true, // 启用代码折叠
                extraKeys: {
                    "Ctrl-Q": function (cm) { cm.foldCode(cm.getCursor()); },
                    "Ctrl-S": function (cm) { cm.execCommand("save"); },
                    "Ctrl-F": "findPersistent",
                    "Ctrl-Space": "autocomplete"
                }
            },
            defaultJson: JSON.stringify({
                "ruleChain": {
                    "id": "chain_call_rest_api",
                    "name": "测试规则链",
                    "debugMode": false,
                    "root": true,
                    "additionalInfo": {
                        "layoutX": "280",
                        "layoutY": "280"
                    }
                },
                "metadata": {
                    "endpoints": [],
                    "nodes": [
                        {
                            "id": "s1",
                            "additionalInfo": {
                                "description": "ddd",
                                "layoutX": 450,
                                "layoutY": 240
                            },
                            "type": "jsFilter",
                            "name": "过滤",
                            "debugMode": true,
                            "configuration": {
                                "jsScript": "return msg!='bb';"
                            }
                        },
                        {
                            "id": "s2",
                            "additionalInfo": {
                                "description": "",
                                "layoutX": 670,
                                "layoutY": 280
                            },
                            "type": "jsTransform",
                            "name": "转换",
                            "debugMode": true,
                            "configuration": {
                                "jsScript": "metadata['test']='test02';\nmetadata['index']=52;\nmsgType='TEST_MSG_TYPE2';\nmsg['aa']=66;\nreturn {'msg':msg,'metadata':metadata,'msgType':msgType};"
                            }
                        },
                        {
                            "id": "s3",
                            "additionalInfo": {
                                "description": "",
                                "layoutX": 930,
                                "layoutY": 190
                            },
                            "type": "restApiCall",
                            "name": "推送数据",
                            "debugMode": true,
                            "configuration": {
                                "headers": {
                                    "Content-Type": "application/json"
                                },
                                "maxParallelRequestsCount": 200,
                                "requestMethod": "POST",
                                "restEndpointUrlPattern": "http://127.0.0.1:9090/api/v1/webhook/test"
                            }
                        },
                        {
                            "id": "node_5",
                            "additionalInfo": {
                                "description": "",
                                "layoutX": 920,
                                "layoutY": 370
                            },
                            "type": "log",
                            "name": "记录错误日志",
                            "debugMode": false,
                            "configuration": {
                                "jsScript": "return 'Incoming message:\\\\n' + JSON.stringify(msg) +\n  '\\\\nIncoming metadata:\\\\n' + JSON.stringify(metadata);"
                            }
                        }
                    ],
                    "connections": [
                        {
                            "fromId": "s1",
                            "toId": "s2",
                            "type": "True"
                        },
                        {
                            "fromId": "s2",
                            "toId": "s3",
                            "type": "Success"
                        },
                        {
                            "fromId": "s2",
                            "toId": "node_5",
                            "type": "Failure"
                        }
                    ]
                }
            }, null, 2)
        }
    },
    watch: {
        value: {
            async handler(val) {
                this.dialogVisible = val;
                await this.init();
            },
            immediate: true
        },
        dialogVisible: {
            handler(val) {
                this.$emit('input', val);
            }
        }
    },
    methods: {
        handleClose() {
            this.$emit('input', false);
        },
        async handleConfirm() {
            await this.submit();
            this.$emit('input', false);
        },
        async submit() {
            this.loading = true;
            try {
                const o = JSON.parse(this.model.contentText)
                const chainId = o.ruleChain.id;
                await api.createRule(o, chainId)
                this.$message({
                    message: 'Save success',
                    type: 'success'
                });
            }
            catch (error) {
                this.$message({
                    message: 'Save failed',
                    type: 'error'
                });
            }
            this.loading = false;
        },
        async fetchData() {
            this.loading = true;
            const o = await api.getRuleById(this.id)
            this.model.contentText = JSON.stringify(o, null, 2)
            this.loading = false;
        },
        async init() {
            if (this.id) {
                await this.fetchData();
            }
            else {
                this.model.contentText = this.defaultJson;
            }
        }
    },
}
</script>
<style>
.rule-go-json-editor .el-dialog {
    color: #dddddd;
    background: #182130;
}

.rule-go-json-editor .el-dialog .el-dialog__header .el-dialog__title {
    color: #dddddd;
}

.rule-go-json-editor .el-dialog .el-dialog__body {
    color: #cfd1d4;
}
.CodeMirror {
  height: auto;
}
/* Custom scrollbar styles */
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
    background-color: #2e2e2e !important;
    /* Dark background for the scrollbar */
}

.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar {
    background-color: #2e2e2e;
    /* Dark background for the scrollbar */
}

.CodeMirror-vscrollbar::-webkit-scrollbar,
.CodeMirror-hscrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-thumb,
.CodeMirror-hscrollbar::-webkit-scrollbar-thumb {
    background-color: #555;
    /* Darker thumb color */
    border-radius: 6px;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-thumb:hover,
.CodeMirror-hscrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #888;
    /* Lighter thumb color on hover */
}
</style>